<template>
  <VCard class="px-2 py-2 mb-2">
    <VRow class="align-center" no-gutters>
      <VCol cols="auto">
        <VTextField
          v-model="searchString"
          @input="debounceInput"
          hide-details
          prepend-inner-icon="mdi-magnify"
          dense
          loader-height="2"
          :loading="searchLoading"
          clearable
          solo
          :placeholder="$t('form.search')"
          @keypress="validationForTitle($event)"
          @paste="validationForTitle($event)"
        />
      </VCol>
    </VRow>
    <VDivider class="mt-3" />
    <div v-if="!items.length && !loading" class="text-center">
      <h3>{{ $t("table.sorry_no_data") }}</h3>
    </div>
    <v-simple-table class="my-table" :dense="!isLarge" v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center x-sm"><VIcon dense>mdi-tooltip-image</VIcon></th>
            <th class="text-left font-weight-bold">{{ $t("table.name") }}</th>
            <th class="text-left font-weight-bold">{{ $t("table.amount") }}</th>
            <th class="text-left font-weight-bold">{{ $t("supply.available") }}</th>
            <th v-if="permissions.client" class="text-left font-weight-bold">
              {{ $t("table.reserve") }}
            </th>
            <th
              v-if="permissions.client && permissions.access_crm"
              class="text-left font-weight-bold"
            >
              {{ $t("table.required") }}
            </th>
            <th class="text-left font-weight-bold">{{ $t("table.bar_code") }}</th>
            <th class="icon"></th>
          </tr>
        </thead>
        <tbody name="fade" is="transition-group">
          <tr v-for="product in items" :key="product.uuid">
            <td>
              <img
                v-if="!product.product_details.path_img_thumb"
                :src="require('@/assets/images/supply-item.png')"
                alt=""
                class="image-placeholder"
                :class="{ sm: !isLarge }"
              />
              <v-img
                v-else
                :src="product.product_details.path_img_thumb"
                class="image"
                @click="showImgDialog(product)"
                v-show="isImgLoaded"
                @load="showImg"
                :class="{ sm: !isLarge }"
              >
                <template v-slot:placeholder>
                  <VRow class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      :size="isLarge ? '20' : '10'"
                      :width="isLarge ? '2' : '1'"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </VRow>
                </template>
              </v-img>
            </td>
            <td class="copyTxt" @click="onSendClick(product)">
              {{ getItemDetailsName(product.product_details) }}
            </td>
            <td>{{ product.count || "--" }}</td>

            <td v-if="!permissions.client">
              <VRow no-gutters justify="space-between" align="center">
                <VCol cols="auto">
                  {{ product.count || 0 }}
                </VCol>
                <VCol cols="auto">
                  <VMenu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :offset-x="true"
                    transition="slide-x-transition"
                    min-width="300"
                    v-if="product.options_details"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VBtn icon v-bind="attrs" v-on="on">
                        <VIcon :small="!isLarge">
                          mdi-information-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    <VCard>
                      <v-simple-table class="my-table plans w-100" :dense="!isLarge">
                        <thead>
                          <tr>
                            <th>{{ $t("products.option") }}</th>
                            <th class="sm">{{ $t("table.amount") }}</th>
                          </tr>
                        </thead>
                        <tbody v-for="option in product.options_details" :key="option.uuid">
                          <tr></tr>
                          <tr>
                            <td>{{ option.name || "--" }}</td>
                            <td>{{ option.amount }}{{ $t("table.piece") }}</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </v-simple-table>
                    </VCard>
                  </VMenu>
                </VCol>
              </VRow>
            </td>
            <td v-else>
              <VRow no-gutters justify="space-between" align="center">
                <VCol cols="auto">
                  {{ product.available }}
                </VCol>
                <VCol cols="auto">
                  <VMenu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :offset-x="true"
                    transition="slide-x-transition"
                    min-width="300"
                    v-if="product.options_details"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VBtn icon v-bind="attrs" v-on="on">
                        <VIcon :small="!isLarge">
                          mdi-information-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    <VCard>
                      <v-simple-table class="my-table plans w-100" :dense="!isLarge">
                        <thead>
                          <tr>
                            <th>{{ $t("products.option") }}</th>
                            <th class="sm">{{ $t("table.amount") }}</th>
                          </tr>
                        </thead>
                        <tbody v-for="option in product.options_details" :key="option.uuid">
                          <tr></tr>
                          <tr>
                            <td>{{ option.name || "--" }}</td>
                            <td>{{ option.available }} {{ $t("table.piece") }}</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </v-simple-table>
                    </VCard>
                  </VMenu>
                </VCol>
              </VRow>
            </td>
            <td v-if="permissions.client" class="sm">
              <VRow no-gutters justify="space-between" align="center">
                <VCol cols="auto">
                  {{ product.reserved || "--" }}
                </VCol>
                <VCol cols="auto">
                  <VMenu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :offset-x="true"
                    transition="slide-x-transition"
                    min-width="300"
                    v-if="product.options_details"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VBtn icon v-bind="attrs" v-on="on">
                        <VIcon :small="!isLarge">
                          mdi-information-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    <VCard>
                      <v-simple-table class="my-table plans w-100" :dense="!isLarge">
                        <thead>
                          <tr>
                            <th>{{ $t("products.option") }}</th>
                            <th class="sm">{{ $t("table.amount") }}</th>
                          </tr>
                        </thead>
                        <tbody v-for="option in product.options_details" :key="option.uuid">
                          <tr></tr>
                          <tr v-if="option.reserved > 0">
                            <td>{{ option.name || "--" }}</td>
                            <td>{{ option.reserved }} {{ $t("table.piece") }}</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </v-simple-table>
                    </VCard>
                  </VMenu>
                </VCol>
              </VRow>
            </td>
            <td
              v-if="permissions.client && permissions.access_crm"
              class="sm error--text font-weight-bold"
            >
              {{
                product.required == 0
                  ? "--"
                  : Math.abs(
                      Number(product.available) - Number(product.amount) + Number(product.required)
                    ) || "--"
              }}
            </td>
            <td class="copyTxt md" @click="CopyToClipboard(product.product_details.bar_code)">
              {{ product.product_details.bar_code || "--" }}
            </td>
            <td class="icon">
              <VBtn icon color="green" :small="!isLarge" @click="onSendClick(product)">
                <VIcon :small="!isLarge">mdi-format-list-bulleted</VIcon>
              </VBtn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-progress-linear v-if="isLoadingFilter" indeterminate color="primary"></v-progress-linear>
    <!-- <div class="text-center mt-3">
        <VBtn
          depressed
          color="blue"
          outlined
          dark
          v-if="!loadMoreDisabled"
          @click="nextPage"
          class="br-10"
        >
          Завантажити більше
        </VBtn>
      </div> -->
    <div
      v-observer="{ nextPage: nextPage }"
      class="observer"
      v-if="!loadMoreDisabled && !loading"
    ></div>

    <CreateProductDialog :visible="createDialog" :cells="cells" @close="createDialog = false" />
    <ViewProductDialog
      v-if="viewProduct"
      :visible="viewProductDialog"
      @close="
        viewProductDialog = false;
        viewProduct = null;
      "
      :item="viewProduct"
    />

    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </VCard>
</template>

<script>
import _ from "lodash";
import depotService from "../../services/request/depot/depotService";
// import ProductsList from './ProductsList.vue';
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import language from "../../mixins/language";
import CreateProductDialog from "./CreateProductDialog.vue";
import EventBus from "../../events/EventBus";
import ViewProductDialog from "./ViewProductDialog.vue";
import imgDialog from "./imgDialog.vue";
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import {uniqueByUUID, validationForTitle} from "../../mixins/helpers";
import SizeUi from "../../mixins/SizeUi";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  name: "ProductsComponent",
  mixins: [loader, notifications, language, user, paginationMixin, SizeUi],
  components: {
    // ProductsList,
    CreateProductDialog,
    ViewProductDialog,
    imgDialog
  },
  data: () => ({
    isLoadingFilter: false,
    viewProductDialog: false,
    viewProduct: null,
    items: [],
    cells: [],
    createDialog: false,
    searchString: "",
    searchLoading: false,
    page: 1,
    total: 24,
    lastPage: 10,
    paginatedProducts: [],
    firstNumber: "",
    secondNumber: "",
    loadMoreDisabled: false,
    imgDialog: false,
    imgpath: [],
    isImgLoaded: false,
    loading: false,
    loadTime: 0
  }),
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
  },
  methods: {
    validationForTitle,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    CopyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(product) {
      this.imgpath = product.product_details.path_img;
      // orderDialog(this.imgpath);
      this.imgDialog = true;
    },
    debounceInput: _.debounce(function() {
      this.onSearchProducts();
    }, 500),
    onSendClick(product) {
      this.$router
        .push({
          name: "products-bar-code",
          params: {
            barCode: product.uuid_product
            // barCode: product.product_details.bar_code,
          }
        })
        .catch(() => {});
    },
    onChangePerPage() {
      this.page = 1;
    },
    getCellName(cellDetails) {
      return cellDetails?.name;
    },
    getCellDesc(cellDetails) {
      return cellDetails?.description;
    },
    getItemDetailsName(itemDetails) {
      return itemDetails?.name || "--";
    },
    getItemDetailsDesc(itemDetails) {
      return itemDetails?.description;
    },
    async onSearchProducts() {
      this.searchLoading = true;
      await this.getItems(
        {
          product_details__name: this.searchString,
          uselike: true,
          offset: 0
        },
        true
      );
      this.searchLoading = false;
    },
    async getItems(params = {}, isSearch = false) {
      try {
        this.loading = true;
        const newItems = await depotService.getMyProducts({
          groupby: "uuid_product",
          id_state: 810,
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params
        });
        // orderDialog(newItems);
        if (isSearch) {
          this.items = newItems;
        } else {
          this.items.push(...newItems);
        }
        // orderDialog("this.items", this.items);
        this.items = [...uniqueByUUID(this.items)];
        // orderDialog(this.items);
        this.loadMoreDisabled = newItems.length < this.perPage;
        // if(!this.permissions.client){
        //   this.cells = await depotService.getCellsList();
        // }
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.setLoading(false);
        this.searchLoading = false;
        console.log(e)
      }
    },
    async emitPageChange() {
      try {
        this.reorderNumbers();
        this.isLoadingFilter = true;
        await this.getItems();
        this.isLoadingFilter = false;
      } catch (e) {
        console.log(e)
        this.isLoadingFilter = false;
      }
    },
    paginateItems() {
      this.paginatedProducts = Object.values(this.items).slice(
        this.perPage * (this.page - 1),
        this.page * this.perPage
      );
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
      this.lastPage = Math.ceil(this.total / this.perPage);
    }
  },
  beforeDestroy(){
    EventBus.$off("added-product");
  },
  async mounted() {
    EventBus.$on("added-product", async () => {
      this.setLoading(true);
      this.page = 1;
      await this.getItems();
      this.paginateItems();
    });
    this.setLoading(true);
    await this.getItems();
    this.paginateItems();
    this.checkLoadTime();
  }
};
</script>

<style scoped lang="scss"></style>
